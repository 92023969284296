<template>
  <v-container class="pa-0 mt-5 about-section" fluid>
    <background-video :settings="backgroundVideo"></background-video>
    <div class="to-front">
      <div class="d-flex flex-row justify-start align-center">
        <page-headline :text="headline"></page-headline>
      </div>
      <div class="ma-5 flex-grow-1">
        <v-card elevation="2" v-for="(item, index) in about" :key="index" style="min-height: 25vh;" class="mb-5 pa-5 d-flex flex-column justify-center about-card">
          <v-card-title class="card-headline">{{ item.headline }}</v-card-title>
          <v-card-text class="about-card-text">
            {{ item.body }}
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script>
import { gsap } from "gsap";
import { defaultBack } from "../helpers/video-background-paths";
export default {
  mounted() {
    this.$store.dispatch("getAbout");
    document.querySelector("html").style.overflowY = "visible";
    let vcards = document.querySelectorAll(".v-card");
    [...vcards].forEach((vcard, index) => {
      gsap.fromTo(vcard, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.35, delay: (index + 1) / 4 });
    });
  },
  data() {
    return {
      headline: "ABOUT US",
      backgroundVideo: {
        sources: [defaultBack.video],
        img: defaultBack.image,
        opacity: 1,
      },
      // Cards are now loaded asynchronously via the public/about.json file.
      /* cards: [
        {
          headline: `HEADQUARTERED IN BURBANK, CALIFORNIA`,
          body: `XRM Media is an entertainment and media technology entity, with state-of-the art production facilities, dedicated to combining compelling storytelling with ingenious distribution to push the limits of creativity in both the traditional and new media space. Our mandate is to be forward looking, out of the box thinking and stay unconventional in redefining an intersection where content and technology are seamlessly weaved together.`,
        },
        {
          headline: `OUR MEDIA CAPABILITIES`,
          body: `XRM Media’s interests vary across all stages of the development, production and distribution processes, including but not limited to, equity financing, co-financing, financial modeling and fund management.  We can develop and produce any form of content across any medium as well as self-distribute, focusing first on mobile and digital platforms.`,
        },
        {
          headline: `OUR GLOBAL ECO-SYSTEM`,
          body: `Through our strategic partnerships and venture capital investments in over 26 countries, we are driven by our entrepreneurial culture to take risks to create unique business models, franchise concepts and/or and innovative platforms that resonate with a global audience.  Our comprehensive holding of portfolio companies provides a dynamic environment— with vast capabilities at your disposal.`,
        },
      ], */
    };
  },
  computed: {
    about: {
      get() {
        return this.$store.getters.about;
      },
    },
  },
  components: {
    "background-video": require("@/components/shared/BackgroundVideo.vue").default,
    "page-headline": require("@/components/shared/PageHeadline.vue").default,
  },
};
</script>

<style lang="scss">
.about-section {
  .about-card {
    background-color: rgba(0, 0, 0, 0.95) !important;
    color: #fff;
  }

  .card-headline {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    white-space: pre-wrap;
    word-break: break-word;
    color: #fff;
  }

  .about-card-text {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #fff !important;
    margin-bottom: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media only screen and (min-width: 1264px) {
    .card-headline {
      font-size: 1.75rem;
      line-height: 3rem;
    }

    .about-card-text {
      font-weight: 300;
    }
  }
}
</style>
